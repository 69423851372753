import _ from "lodash";
import { colors, createMuiTheme, responsiveFontSizes } from "@material-ui/core";
import typography from "./typography";

const baseOptions = {
  typography,
  overrides: {
    PrivateTabIndicator: {
      colorSecondary: {
        backgroundColor: "#ff157a",
      },
    },
    MuiFormControlLabel: {
      root: {
        marginLeft: "0",
        marginRight: "0",
      },
    },
    MuiDrawer: {
      paper: {
        backgroundColor: " #19194b",
      },
    },
    MuiLinearProgress: {
      root: {
        borderRadius: 3,
        overflow: "hidden",
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: 32,
      },
    },
    MuiChip: {
      root: {
        backgroundColor: "rgba(0,0,0,0.075)",
      },
    },

    MuiSelect:{
      select:{
        "&:focus:":{
          backgroundColor:"transparent"
        }
      }
    },
    MuiSelect:{
      icon:{
    top: "calc(50% - 15px)",
    color: "rgba(0, 0, 0, 0.54)",
    right: '0',
    position: "absolute",
  
      }
    },
    MuiButton: {
      contained: {
        boxShadow: "none",
        fontSize:'14px',
      
       borderRadius: 27,
        "&:hover": {
          boxShadow: "none",
        },
      },
      outlined: {
        boxShadow: "none",
        borderRadius: 27,
        borderWidth: 2,
      },
      outlinedPrimary: {
        borderRadius: 27,
        boxShadow: "none",
      },
      containedSizeLarge: {
        color: "#ffffff",
        padding: "14px 50px",
      },
    },
    MuiInput: {
      underline: {
        "&::before": {
          display: "none",
        },
        "&::after": {
          display: "none",
        },
      },
    },
    MuiInputBase: {
      inputMultiline: {
        fontSize: "14px !important",
        height: "50px !important",
      },
      multiline: {
        padding: "20.5px 9px !important",
        fontSize: "14px !important",
        borderRadius: "0px !important",
        height: "70px !important",
      },
      input: {
        height: "25px !important",
        borderRadius: "50px !important",
        borderRadius: "0px !important",
      },
    },
  },
  MuiFormControl: {
    MuiOutlinedInput: {
      input: {
        border: "1px solid grey",
        height: "35px",
        padding: "10px",
        fontSize: "14px",
        marginTop: "5px",
        borderRadius: "20px",
        position: "relative",
      },
      notchedOutline: {
        borderColor: "#000",
      },
    },
  },
};

const themesOptions = {
  typography: {
    fontWeight: 400,
  },
  palette: {
    type: "light",
    action: {
      primary: "#20509e",
    },
    background: {
      default: "#FBFBFD",
      dark: "#f3f7f9",
      paper: colors.common.white,
    },
    primary: {
      main: "#313b48",
      dark: "#202831",
      light: "#e2e3fa",
    },
    secondary: {
      main: "#ff0610",
    },
    warning: {
      main: "#ffae33",
      dark: "#ffae33",
      light: "#fff1dc",
    },
    success: {
      main: "#54e18c",
      dark: "#54e18c",
      light: "#e2faec",
    },
    error: {
      main: "#ff7d68",
      dark: "#ff7d68",
      light: "#ffe9e6",
    },
    text: {
      primary: "#52565c",
      secondary: "#999999",
    },
    common: {
      black: "#222222",
    },
  },
};

export const createTheme = (config = {}) => {
  let theme = createMuiTheme(_.merge({}, baseOptions, themesOptions));

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};
