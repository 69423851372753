import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
import DashboardLayout from "src/layouts/DashboardLayout";
import LoginLayout from "src/layouts/LoginLayout";
import HomeLayout from "./layouts/HomeLayout";
export const routes = [
  // {
  //   exact: true,
  //   path: "/",
  //   layout: LoginLayout,
  //   component: lazy(() => import("src/views/auth/LogIn/index")),
  // },

  {
    exact: true,
    path: "/signup",
    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/LogIn/SignUp")),
  },
  {
    exact: true,
    path: "/signin",
    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/LogIn/SignIn")),
  },
  {
    exact: true,
    path: "/",
    guard: false,
    layout: HomeLayout,
    component: lazy(() => import("src/views/Home/index")),
  },
  {
    exact: true,
    path: "/supported-exchanges",
    layout: HomeLayout,
    component: lazy(() => import("src/views/Home/ExchangesList")),
  },
  {
    exact: true,
    path: "/press",
    layout: HomeLayout,
    component: lazy(() => import("src/views/Home/Press")),
  },
  {
    exact: true,
    path: "/news",
    layout: HomeLayout,
    component: lazy(() => import("src/views/Home/News")),
  },
  {
    exact: true,
    path: "/ourPartners",
    layout: HomeLayout,
    component: lazy(() => import("src/views/Home/OurPartners")),
  },
  {
    exact: true,
    path: "/support",
    layout: HomeLayout,
    component: lazy(() => import("src/views/Home/Support")),
  },
  {
    exact: true,
    path: "/about",
    layout: HomeLayout,
    component: lazy(() => import("src/views/Home/About")),
  },
  {
    exact: true,
    path: "/exchange-widget",
    layout: HomeLayout,
    component: lazy(() => import("src/views/Home/ExchangeWidget")),
  },
  {
    exact: true,
    path: "/price",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Price")),
  },
  {
    exact: true,
    path: "/dashboard/price",
    // layout: HomeLayout,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Price")),
  },
  {
    exact: true,
    path: "/help-center",
    // layout: HomeLayout,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/Home/FAQ")),
  },

  {
    exact: true,
    path: "/faq",
    // layout: HomeLayout,
    layout: HomeLayout,
    component: lazy(() => import("src/views/Home/FAQ")),
  },
  {
    exact: true,
    path: "/confirm-email",
    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/LogIn/ConfirmEmail")),
  },
  {
    exact: true,
    path: "/newPassword",
    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/LogIn/NewPassword")),
  },
  {
    exact: true,
    path: "/email-OTP",
    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/LogIn/EmailCode")),
  },
  {
    exact: true,
    path: "/emailOTP",
    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/LogIn/EmailCodeNew")),
  },

  {
    exact: true,
    path: "/login",
    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/LogIn/Login")),
  },
  {
    exact: true,
    path: "/terms",
    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/LogIn/Terms")),
  },
  {
    exact: true,
    path: "/contact-us",
    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/LogIn/Contactus")),
  },
  {
    exact: true,
    path: "/policy",
    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/LogIn/Policy")),
  },
  {
    exact: true,
    path: "/dashboard",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard")),
  },

  {
    exact: true,
    path: "/getList",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Exchange/GetList")),
  },
  {
    exact: true,
    path: "/404",
    component: lazy(() => import("src/views/errors/NotFound")),
  },
  {
    exact: true,
    path: "/dashboard",
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard")),
  },
  // {
  //   exact: true,
  //   path: "/dashboard/exchange",
  //   guard: true,
  //   layout: DashboardLayout,
  //   component: lazy(() =>
  //     import("src/views/pages/Dashboard/Exchange/exchange")
  //   ),
  // },
  {
    exact: true,
    path: "/settings",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Settings")),
  },
  {
    exact: true,
    path: "/arbitrageDirect",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/ArbitrageDirect")),
  },
  {
    exact: true,
    path: "/arbitragetriangular",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/ArbitrageTriangular")
    ),
  },
  {
    exact: true,
    path: "/arbitrageloop",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/ArbitrageLoop")),
  },
  {
    exact: true,
    path: "/arbitrageintraexchange",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/ArbitrageIntraExchange")
    ),
  },
  {
    exact: true,
    path: "/newRule",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/NewRule")),
  },
  {
    exact: true,
    path: "/analytics",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Analytics")),
  },
  {
    exact: true,
    path: "/dashboard/exchange",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/Exchange/exchange1")
    ),
  },
  {
    exact: true,
    path: "/dashboard/exchange2",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/Exchange/exchange2")
    ),
  },
  {
    exact: true,
    path: "/dashboard/exchange3",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/Exchange/exchange3")
    ),
  },
  {
    exact: true,
    path: "/dashboard/stats",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Stats/stats1")),
  },
  {
    exact: true,
    path: "/dashboard/chart",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Charts/chart")),
  },
  {
    exact: true,
    path: "/view-rule-event",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/component/ViewRuleEvent")),
  },
  {
    exact: true,
    path: "/livetrack",
    // guard:true,
    // layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/LiveTracking")),
  },
  {
    component: () => <Redirect to="/404" />,
  },
];
