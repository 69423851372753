import React, { useState, useEffect } from "react";
import clsx from "clsx";
import Modal from "@material-ui/core/Modal";
import { AuthContext } from "src/context/Auth";
import { UserContext } from "src/context/UserAuth";
// import { UserContext } from "src/context/UserAuth";
// UserContext
import PropTypes from "prop-types";
import {
  AppBar,
  Box,
  Toolbar,
  makeStyles,
  IconButton,
  Badge,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
  Grid,
  // Hidden,
} from "@material-ui/core";
import NotificationsIcon from "@material-ui/icons/Notifications";
import { FaSignOutAlt } from "react-icons/fa";
import { RiLogoutBoxRLine } from "react-icons/ri";
// import SwipeableTemporaryDrawer from "./RightDrawer";
import { useHistory } from "react-router-dom";
import Logo from "src/component/Logo";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import SyncIcon from "@material-ui/icons/Sync";
import Axios from "axios";
import ApiConfig from "src/config/APICongig";
import ExpandMore from "@material-ui/icons/ExpandMore";
import ButtonCircularProgress from "src/component/ButtonCircularProgress";
// import CheckCircleOutline from "@material-ui/icons/CheckCircleOutline";
// import Share from "@material-ui/icons/Share";
// import BlockIcon from "@material-ui/icons/Block";
// import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
// import Close from "@material-ui/icons/Close";
import { BiRupee } from "react-icons/bi";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.paper,
  },
  toolbar: {
    height: 80,
    "& .MuiIconButton-root": {
      padding: "0px",
    },
  },
  logo: {
    marginRight: theme.spacing(2),
  },
  logo1: {
    width: "150px",
    height: "33px",
    cursor: "pointer",
    paddingLeft: 16,
    "@media (max-width: 400px)": {
      width: "100px",
      height: "20px",
      padding: "0",
    },
    "@media (max-width: 500px, min-width: 400px)": {
      width: "140px",
      height: "30px",
      padding: "0",
    },
  },

  icon: {
    color: "#fff",
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(3),
    // marginLeft: theme.spacing(1),
  },
  link: {
    fontWeight: theme.typography.fontWeightMedium,
    "& + &": {
      marginLeft: theme.spacing(2),
    },
  },
  divider: {
    width: 1,
    height: 32,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  Accordian: {
    // width: "600px",
    "& .MuiAccordionDetails-root": {
      display: "block",
    },
  },
  handleDialog: {
    "@media (max-width:500px)": {
      minwidth: "200px",
    },
    "& .MuiDialog-paperScrollPaper": { maxHeight: "78vh" },
    "& .walletheading": {
      width: " 500px",
      margin: 0,
      display: "flex",
      alignItems: " center",
      justifyContent: "space-between",
      borderBottom: " 1px solid #cecece",
      padding: " 5px",
      paddingBottom: "20px",
      fontSize: "14px",
      color: "#000",
      position: "relative",
      [theme.breakpoints.down("md")]: {
        width: " 100%  ",
      },
      "& span": {
        position: "absolute",
        bottom: 3,
        right: 5,
        fontSize: "12px",
        color: "#9e9e9e",
      },
    },
    "& .notificationexpand": {
      textAlign: "center",
    },
    "& .MuiDialogContent-root": {
      "@media (max-width:500px)": {
        // width: "264px",
        // textAlign: "center",
        width: "307px",
        // display: "flex",
        // flexDirection: "column",
      },
      "@media (max-width:380px)": {
        // width: "264px",
        // textAlign: "center",
        width: "250px",
        // display: "flex",
        // flexDirection: "column",
      },

      "@media (max-width:350px)": {
        // width: "264px",
        // textAlign: "center",
        width: "210px",
        // display: "flex",
        // flexDirection: "column",
      },
    },
    "& .MuiDialogActions-root": {
      display: "flex",
      justifyContent: "center",
    },
    "& .MuiDialog-container": {
      position: "absolute",
      right: 1,
      top: "6%",
    },
    "& .MuiDialog-scrollPaper": {
      display: "flex",
      alignItems: "start",
      justifyContent: "center",
    },
  },
  namePlace: {
    "& .MuiDialog-container": {
      maxHeight: "78%",
      minWidth: "214",
    },
  },

  newRuleOn: {
    border: "1px solid black",
    width: "300px",
    height: "160px",
    // position: "absolute",
    display: "none",
    alignItems: "center",
    marginTop: "40px",
    top: "50%",
    right: "0%",
  },
  handleHide: {
    display: "none",
  },
  handleShow: {
    display: "block",
  },
}));

const TopBar = ({ className, onMobileNavOpen, ...rest }) => {
  const classes = useStyles();

  return (
    <AppBar className={clsx(classes.root, className)} color="default" {...rest}>
      <Toolbar className={classes.toolbar}>
        <TopBarData />
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
};
TopBar.defaultProps = {
  onMobileNavOpen: () => { },
};

export default TopBar;

export function TopBarData(props) {
  const classes = useStyles();
  const auth = React.useContext(AuthContext);
  const user = React.useContext(UserContext);
  // const user = React.useContext(UserContext);
  const [open4, setOpen4] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);

  const handleOpen = () => {
    readNotificationHandler();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClose1 = () => {
    setOpen1(false);
  };
  const handleClose4 = () => {
    setOpen4(false);
  };
  const history = useHistory();
  const [isUpdating1, setIsUpdating1] = useState(false);
  const [exchangeNameList, setExchangeNameList] = useState([]);
  const [list, setList] = useState([]);
  const [allExchangeTotal, setallExchangeTotal] = useState("");

  const handleOpen1 = async () => {
    APIHitOnRefresh();
    setOpen1(true);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open1) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]); //eslint-disable-line

  const APIHitOnRefresh = async () => {
    setIsUpdating1(true);
    await Axios({
      method: "POST",
      url: ApiConfig.getExchangeBalance,
      headers: {
        token: window.localStorage.getItem("token"),
      },
    })
      .then(async (res) => {
        if (res.data.responseCode === 200) {
          var get = document.getElementById("re-fresh");
          get.style.display = "block";
          setExchangeNameList(res.data.result?.responseResult);
          setList(res.data.result);
          setallExchangeTotal(res.data.result?.allExchangeTotal);
          auth.exchange(res.data.result?.responseResult);
          setIsUpdating1(false);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const readNotificationHandler = async () => {
    await Axios({
      method: "GET",
      url: ApiConfig.readNotification,
      headers: {
        token: window.localStorage.getItem("token"),
      },
    })
      .then(async (res) => {
        if (res.data.responseCode === 200) {
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  useEffect(() => {
    setExchangeNameList(auth.exchangeNameList);
    setallExchangeTotal(auth.allExchangeTotal);
  }, [auth.exchangeNameList, auth.allExchangeTotal]);

  const logOut = () => {
    auth.userLogIn(false, null);
    history.push("/login");
    window.localStorage.removeItem("token");
  };
  const handleClickOpen = (id) => {
    setOpen4(true);
  };
  const HiddenShow = async () => {
    var get = document.getElementById("showNotification");
    get.style.display = "none";
    var get1 = document.getElementById("showNotification1");
    get1.style.display = "none";
    var set = document.getElementById("hideNotification");
    set.style.display = "block";
  };
  const HiddenShow1 = async () => {
    var get = document.getElementById("re-fresh");
    get.style.display = "none";
    var get1 = document.getElementById("showWallet");
    get1.style.display = "none";
    var set = document.getElementById("re-fresh1");
    set.style.display = "block";
  };
  return (
    <>
      <Logo className={classes.logo1} onClick={() => history.push("/")} />

      <Box flexGrow={1} />
      <IconButton>
        <Badge badgeContent={0} color="secondary">
          <AccountBalanceWalletIcon
            onClick={() => handleOpen1()}
            style={{ color: "white", padding: "12px" }}
          />

          <Modal className={classes.Accordian}>
            <Box className="walletmodal">
              <Box className="walletbox">
                <p className="headingtext">My Wallet </p>
                <span className="walletspan1">
                  {" "}
                  Total = ${allExchangeTotal}
                </span>
                <Button onClick={() => APIHitOnRefresh()}>
                  <Typography variant="h6" className="walletspan">
                    {!isUpdating1 && <SyncIcon />}
                    {isUpdating1 && <ButtonCircularProgress />}
                    &nbsp;Refresh
                  </Typography>{" "}
                </Button>
              </Box>

              <Box id="re-fresh" open={!isUpdating1}>
                {exchangeNameList &&
                  exchangeNameList?.map((data, i) => {
                    return (
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMore />}
                          aria-controls="panel2a-content"
                          id="panel2a-header"
                        >
                          <Typography>{data?.exchangeName}</Typography>
                        </AccordionSummary>
                        {data?.AccountBalance &&
                          data?.AccountBalance?.map((data, i) => {
                            return (
                              <AccordionDetails>
                                <Box
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <Box>
                                    <Typography variant="h6">
                                      {data?.asset}
                                    </Typography>
                                  </Box>
                                  <Box pr={2}>
                                    <Typography variant="body2">
                                      {data?.free}~={data?.total}{" "}
                                      {data?.totalBalance}
                                    </Typography>
                                  </Box>
                                </Box>
                              </AccordionDetails>
                            );
                          })}
                      </Accordion>
                    );
                  })}
              </Box>

              <p className="walletexpand"> Expand List</p>
            </Box>
          </Modal>
        </Badge>

        <Badge
          className={classes.namePlace}
          badgeContent={user.NotificationCount}
          color="secondary"
          // auth.unreadCount !== 0 ?
          variant={user.unreadCount === 0 ? "" : "dot"}
        >
          <NotificationsIcon onClick={handleOpen} style={{ color: "white" }} />
          <Dialog
            open={open}
            onClose={handleClose}
            className={classes.handleDialog}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
          >
            <DialogTitle id="scroll-dialog-title">
              <p className="headingtext" style={{ color: "#19194b" }}>
                Notifications{" "}
              </p>
              {/* <p className="headingtext">View More </p> */}
            </DialogTitle>
            <DialogContent>
              <DialogContentText
                id="scroll-dialog-description"
                ref={descriptionElementRef}
                tabIndex={-1}
              >
                {/* {!ViewMore && ( */}
                <Box id="showNotification" className={classes.handleShow}>
                  {user.notificationList &&
                    user.notificationList.slice(0, 4)?.map((notify, i) => {
                      return (
                        <p
                          // onClick={() => ONNotify(notify._id)}
                          className="walletheading"
                        >
                          {notify.title}
                          <span className="notificationspan1">
                            {moment(notify.createdAt).startOf("second").fromNow()}
                          </span>
                        </p>
                      );
                    })}
                </Box>

                <Box id="hideNotification" className={classes.handleHide}>
                  {user.notificationList &&
                    user.notificationList?.map((notify, i) => {
                      return (
                        <p
                          // onClick={() => ONNotify(notify._id)}
                          className="walletheading"
                        >
                          {notify.title}
                          <span className="notificationspan1">
                            {moment(notify.createdAt).startOf("second").fromNow()}
                          </span>
                        </p>
                      );
                    })}
                </Box>

                {/* </Box> */}
              </DialogContentText>
            </DialogContent>
            <DialogActions id="scroll-dialog-title">
              <Box id="showNotification1" className={classes.handleShow}>
                <button onClick={() => HiddenShow()} className="headingtext">
                  Expand List{" "}
                </button>
              </Box>
            </DialogActions>
          </Dialog>

          {/* -------------------------------- */}
          <Dialog
            open={open1}
            fullWidth
            maxWidth="md"
            onClose={handleClose1}
            className={classes.handleDialog}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
          >
            <DialogTitle id="scroll-dialog-title" style={{ color: "#19194b" }}>
              <Box className="walletbox">
                <p>My Wallet </p>
                <span className="walletspan1">
                  {" "}
                  Total ={" "}
                  {auth.userData?.baseCurrency === "INR" ? (
                    <BiRupee />
                  ) : (
                    "$"
                  )}{" "}
                  {allExchangeTotal ? allExchangeTotal : 0}
                </span>
                <Button onClick={() => APIHitOnRefresh()}>
                  {!isUpdating1 && <SyncIcon />}
                  {isUpdating1 && <ButtonCircularProgress />}
                  &nbsp;Refresh
                </Button>
              </Box>
            </DialogTitle>
            <DialogContent>
              <DialogContentText
                id="scroll-dialog-description"
                ref={descriptionElementRef}
                tabIndex={-1}
              >
                <Box
                  id="re-fresh"
                  open={!isUpdating1}
                  className={classes.handleShow}
                >
                  {exchangeNameList &&
                    exchangeNameList?.slice(0, 3)?.map((data, i) => {
                      // console.log(data);
                      return (
                        <Accordion>
                          <AccordionSummary
                            expandIcon={<ExpandMore />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                          >
                            <Box
                              width="100%"
                              display="flex"
                              justifyContent="space-between"
                            >
                              <Typography>{data?.exchangeName}</Typography>
                              {/* <Typography>
                                {data?.AccountBalance[5]?.totalBalance}
                              </Typography> */}
                              {/* {console.log(
                                "length in no",
                                data?.AccountBalance?.length,
                                "values",
                                data?.AccountBalance
                                // [
                                //   data?.AccountBalance?.length
                                // ]?.totalBalance
                              )} */}
                            </Box>
                          </AccordionSummary>
                          {data?.AccountBalance &&
                            data?.AccountBalance?.map((row, i) => {
                              if (i === data?.AccountBalance?.length) {
                                return; //eslint-disable-line
                              }
                              // console.log(
                              //   "data?.AccountBalance length",
                              //   data?.AccountBalance?.length
                              // );
                              return (
                                <AccordionDetails>
                                  <Grid container spacing={1}>
                                    <Grid item xs={3}>
                                      <Typography
                                        variant="h6"
                                        style={{ color: "#19194b" }}
                                      >
                                        {row?.asset}
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={9}>
                                      <Typography
                                        variant="body2"
                                        style={{ fontSize: " 14px" }}
                                      >
                                        {row?.free}~={row?.total}{" "}
                                        {row?.totalBalance}
                                      </Typography>
                                    </Grid>
                                  </Grid>

                                  {/* <Box
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <Box>
                                      <Typography variant="h6">
                                        {data?.asset}
                                      </Typography>
                                    </Box>
                                    <Box pr={2}>
                                      <Typography variant="body2">
                                        {data?.free}~={data?.total}{" "}
                                        {data?.totalBalance}
                                      </Typography>
                                    </Box>
                                  </Box> */}
                                </AccordionDetails>
                              );
                            })}
                        </Accordion>
                      );
                    })}
                </Box>
                <Box
                  id="re-fresh1"
                  open={!isUpdating1}
                  className={classes.handleHide}
                >
                  {exchangeNameList &&
                    exchangeNameList?.map((data, i) => {
                      // console.log(data);
                      return (
                        <Accordion>
                          <AccordionSummary
                            expandIcon={<ExpandMore />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                          >
                            <Box
                              width="100%"
                              display="flex"
                              justifyContent="space-between"
                            >
                              <Typography>{data?.exchangeName}</Typography>
                              {/* <Typography>
                                {data?.AccountBalance[5]?.totalBalance}
                              </Typography> */}
                              {/* {console.log(
                                "length in no",
                                data?.AccountBalance?.length,
                                "values",
                                data?.AccountBalance
                                // [
                                //   data?.AccountBalance?.length
                                // ]?.totalBalance
                              )} */}
                            </Box>
                          </AccordionSummary>
                          {data?.AccountBalance &&
                            data?.AccountBalance?.map((row, i) => {
                              if (i === data?.AccountBalance?.length) {
                                return; //eslint-disable-line
                              }
                              // console.log(
                              //   "data?.AccountBalance length",
                              //   data?.AccountBalance?.length
                              // );
                              return (
                                <AccordionDetails>
                                  <Grid container spacing={1}>
                                    <Grid item xs={3}>
                                      <Typography
                                        variant="h6"
                                        style={{ color: "#19194b" }}
                                      >
                                        {row?.asset}
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={9}>
                                      <Typography
                                        variant="body2"
                                        style={{ fontSize: " 14px" }}
                                      >
                                        {row?.free}~={row?.total}{" "}
                                        {row?.totalBalance}
                                      </Typography>
                                    </Grid>
                                  </Grid>

                                  {/* <Box
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <Box>
                                      <Typography variant="h6">
                                        {data?.asset}
                                      </Typography>
                                    </Box>
                                    <Box pr={2}>
                                      <Typography variant="body2">
                                        {data?.free}~={data?.total}{" "}
                                        {data?.totalBalance}
                                      </Typography>
                                    </Box>
                                  </Box> */}
                                </AccordionDetails>
                              );
                            })}
                        </Accordion>
                      );
                    })}
                </Box>

                {list.length == 0 && (
                  <Box>
                    <Typography>
                      You have not connected any exchange yet !
                    </Typography>
                  </Box>
                )}
              </DialogContentText>
            </DialogContent>
            {list.length != 0 && (
              <>
                {/* <DialogActions textAlign="center">
                <p textAlign="center" className="walletexpand">
                  Expand List
                </p>
              </DialogActions> */}

                <DialogActions id="scroll-dialog-title">
                  <Box id="showWallet" className={classes.handleShow}>
                    <button
                      onClick={() => HiddenShow1()}
                      className="headingtext"
                    >
                      Expand List{" "}
                    </button>
                  </Box>
                </DialogActions>
              </>
            )}
          </Dialog>
        </Badge>
        <Button
          // onClick={logOut}
          onClick={() => {
            handleClickOpen();
          }}
          activeClassName={classes.active}
          className={clsx(classes.buttonLeaf, `depth-0`)}
        >
          {/* <img src="images/logoutimg.png" width="20px"/> */}
          <RiLogoutBoxRLine size="25" className={classes.icon} />
          {/* <span>{"Logout"}</span> */}
        </Button>
      </IconButton>
      {/* <SwipeableTemporaryDrawer /> */}

      <Dialog
        open={open4}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" style={{ color: "#19194b" }}>
          <Typography style={{color:"#000"}}>Logout</Typography>
        </DialogTitle>
        <DialogContent style={{ marginTop: "-19px" }}>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to logout
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ marginTop: "-20px" }}>
          <Button onClick={handleClose4}>No</Button>
          <Button onClick={logOut} autoFocus>
            Yes{" "}
            {/* {blockLoader && (
              <Box>
                <CircularProgress
                  style={{ height: "20px", width: "20px", marginLeft: "5px" }}
                />
              </Box>
            )} */}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
